import React from 'react'
import "./arrowButton.scss"
import { Spring,animated as a } from 'react-spring'


const CIRCLE = `M181.7,20.8C163.7,10.3,144,5,122.6,5c-21.3,0-41,5.3-59,15.8S31.3,45.6,20.8,63.6C10.2,81.6,5,101.3,5,122.6
s5.2,41,15.8,59c10.5,18,24.8,32.3,42.8,42.8c18,10.5,37.7,15.8,59,15.8c21.4,0,41-5.3,59.1-15.8c18-10.5,32.3-24.8,42.8-42.8
c10.5-18,15.8-37.7,15.8-59s-5.2-41-15.8-59.1C214,45.6,199.7,31.3,181.7,20.8`

const ARROW = `M73.7,99.3l49,58.8l49-58.8`

export default class ArrowButton extends React.Component {
  render() {
    const toggle = !this.props.isShow && this.props.nextPage!==undefined
    const sideWay = this.props.isSideway && this.props.nextPageLabel!==undefined
    const moveAside = this.props.isOnSide

    return (
      <div className={"arrowButtonWrapper " + (sideWay ? "static " : "")}>
        <Spring 
            config={{ tension: 200, friction: 60}}
            to={{ 
              width: moveAside ? "100%" : "50%"
            }}>
              {wrapperStyle => 
            <div style={wrapperStyle} className={"arrowButtonSVGWrapper "  + (moveAside ? "middle" : "")}>
        
          <Spring 
          config={{ tension: 200, friction: 60}}
          to={{ 
               opacity: toggle ? 0.3 : 0,
           }}>
        {buttonStyle => 
          <svg style={buttonStyle} viewBox="0 0 245.3 245.3" className={"arrowButton noHover " + (sideWay ? "sideWay" : "")}>
            <a.g>
            <Spring native
              config={{ delay:250, tension: 200, friction: 60}}
              from ={{
                strokeDashoffset: 1000, 
              }}
              to={{ 
                strokeDashoffset: toggle ? 0 : 1000, 
              }}>
              {circleStyle => 
	              <a.path className={'arrow'} style={circleStyle} d={CIRCLE}/>
              }
            </Spring>

            <Spring native
              config={{ delay:500, tension:200, friction: 60}}
              from ={{
                transform: 'translateY(-50px)',
              }}
              to={{ 
                transform: toggle ? 'translateY(0px)' : 'translateY(-50px)',
              }}>
              {arrowStyle => 
	              <a.path className={'circle'} style={arrowStyle} d={ARROW}/>
              }
            </Spring>
            </a.g>
          </svg>}
        </Spring>
        </div>}
        </Spring>

        <Spring native
            config={{ delay:0, tension:200, friction: 60}}
            to={{ 
              transform: sideWay ? 'translate(10px, 15px)' : 'translate(-30px,  15px)',
              opacity: sideWay ? 0.3 : 0,
            }}>
            {nextPageStyle =><div className="next-page-wrapper">
              <a.span style={nextPageStyle} className="next-page">
                {this.props.nextPageLabel}
              </a.span></div>}
          </Spring>

      </div>
  )
}}