import React from 'react'
import { Spring, animated as a } from 'react-spring'
import { ParallaxLayer } from 'react-spring/addons'

//import Layout from '../components/layout'
import SEO from '../components/seo'
import ParallaxContainer from '../components/parallaxContainer'
import ThreeContainer from '../components/threeContainer'

import * as theConst from '../utils/fileWithConstants';

export default class ParallaxPage extends React.Component {
    render() {
      return (
        <>
        <SEO title="404: Not found" />
    
        <ThreeContainer visualType={'circle'} color={0xec2f4b}></ThreeContainer>

        <ParallaxContainer totalPages={1}>
            {/*Page 1*/}
            <ParallaxLayer offset={0.4} speed={0.0625}>  
                <Spring
                    native
                    config={{ delay:theConst.TIME_DELAY*1 }}
                    from={{ transform:theConst.TRANSLATE_START, opacity: 0 }}
                    to={{ transform:theConst.TRANSLATE_END,opacity: 1 }}>
                    {props =><a.div style={props}>
                      <h1 className="header-middle header-name header-400">Not Found</h1>
                      <p className="header-middle">You just hit a page that doesn&#39;t exist.</p>
                      </a.div>}
                </Spring>
            </ParallaxLayer>
        </ParallaxContainer>
        </>
        )
    }
  }