import React from 'react'
import {navigate} from 'gatsby'
import { Parallax } from 'react-spring/addons'
import ArrowButton from './arrowButton'

let deltaUp = 0
let deltaDown = 0
let currentPage = 0
let touchStart = 0
let touchEnd = 0

const SCROLL_AMOUNT = 1000
const TOUCH_AMOUNT = 400
const NEXTPAGE_DELAY = 100

export default class ParallaxContainer extends React.Component {

    constructor (props) {
        super(props)
        //Attach Scroll Function to variable

        //Bind(this) change this to this component instead of window...
        this.handleScrollFunction = this.handleScroll.bind(this)    
        this.handleTouchStartFunction = this.handleTouchStart.bind(this)    
        this.handleTouchEndFunction = this.handleTouchEnd.bind(this)

        this.state = {
            isBottom: false,
            isSideway: false,
            isMiddle: false,
            currentPage: 0,
        }
    }

    componentDidMount() {
        window.addEventListener('wheel', this.handleScrollFunction, {passive: true})
        window.addEventListener('touchstart', this.handleTouchStartFunction,{passive: true})
        window.addEventListener('touchend', this.handleTouchEndFunction, {passive: true})

        currentPage = 0
        deltaDown = 0
        this.isBottom()        
    }
    
    componentWillUnmount() {
        window.removeEventListener('wheel', this.handleScrollFunction)
        window.removeEventListener('touchstart', this.handleTouchStartFunction)
        window.removeEventListener('touchend', this.handleTouchEndFunction)
    }

    isMiddle() {
        if(this.props.totalPages > 2 ) {
            if(this.props.totalPages > currentPage+1 && currentPage !== 0) {
                this.setState({ isMiddle: true})    
            } else {
                this.setState({ isMiddle: false})
            }
        } else {
            this.setState({ isMiddle: false})
        }
    }

    isBottom() {
        if(this.props.totalPages > currentPage+1) {
            this.setState({ isBottom: false, isSideway:false })
        } else {
            this.setState({ isBottom: true, isSideway:true })
            deltaDown = 0
        }
    }

    handleTouchStart(event) {
        var touchobj = event.changedTouches[0] // reference first touch point for this event
        touchStart = parseInt(touchobj.clientY)
    }

    handleTouchEnd(event) {
        var touchobj = event.changedTouches[0] // reference first touch point for this event
        touchEnd = parseInt(touchobj.clientY)

        if(this.props.totalPages > 1) {
            if(touchEnd > touchStart) {
                if(currentPage > 0) {
                    currentPage--
                    this.refs.parallax.scrollTo(currentPage)
                    if(this.props.threeLayer) this.props.threeLayer.current.children[0].style.top = 0 + this.refs.parallax.space*currentPage + "px"
                    this.isBottom()                    
                    this.isMiddle()
                    this.props.action(currentPage, this.state.isBottom)                                        
                }
                    
            } else  if(touchEnd < touchStart) {
                if((currentPage+1) < this.props.totalPages) {
                    currentPage++
                    this.refs.parallax.scrollTo(currentPage)
                    if(this.props.threeLayer) this.props.threeLayer.current.children[0].style.top = 0 + this.refs.parallax.space*currentPage + "px"
                    this.isBottom()
                    this.isMiddle()
                    this.props.action(currentPage, this.state.isBottom)                                                            
                }

                if(this.state.isBottom) {
                    let distance = touchStart - touchEnd
                    if(distance > TOUCH_AMOUNT && this.props.nextPage!==undefined) {
                        this.sleep(NEXTPAGE_DELAY).then(() => {            
                            navigate(this.props.nextPage,  { replace: false })                
                        })
                    }
                }
            }
        } else if(this.state.isBottom && this.props.nextPage!==undefined) {
            let distance = touchStart - touchEnd
            if(distance > TOUCH_AMOUNT) {
                this.sleep(NEXTPAGE_DELAY).then(() => {            
                    navigate(this.props.nextPage,  { replace: false })                
                })
            }
        }
    }


    handleScroll(event) {
        if(this.props.totalPages > 1) {
            if(event.deltaY > 0) {
                deltaUp = 0
                deltaDown += event.deltaY
                
                deltaDown = Math.abs(deltaDown)
                if(deltaDown / SCROLL_AMOUNT > 1) {
                    if( (currentPage+1) < this.props.totalPages) {
                        deltaDown = 0
                        currentPage++
                        this.refs.parallax.scrollTo(currentPage)
                        if(this.props.threeLayer) this.props.threeLayer.current.children[0].style.top = 0 + this.refs.parallax.space*currentPage + "px"
                        this.isBottom()         
                        this.isMiddle()               
                        this.props.action(currentPage, this.state.isBottom)                                                                
                    }

                    if(this.state.isBottom) {
                        if(event.deltaY > 0) {
                            deltaDown += event.deltaY
                            deltaDown = Math.abs(deltaDown)
                            if(deltaDown / SCROLL_AMOUNT > 1 && this.props.nextPage!==undefined) {
                                this.sleep(NEXTPAGE_DELAY).then(() => {
                                    navigate(this.props.nextPage,  { replace: false })
                                })
                            }
                        }
                    }      
                }
            } else {
                deltaDown = 0
                deltaUp -= event.deltaY
                deltaUp = Math.abs(deltaUp)
                if(deltaUp / SCROLL_AMOUNT > 1) {
                    if(currentPage > 0) {
                        deltaUp = 0
                        currentPage--
                        this.refs.parallax.scrollTo(currentPage)
                        if(this.props.threeLayer) this.props.threeLayer.current.children[0].style.top = 0 + this.refs.parallax.space*currentPage + "px"
                        this.isBottom()                                                
                        this.isMiddle()
                        this.props.action(currentPage, this.state.isBottom)                                                                
                    }
                }
            }
        } else if(this.state.isBottom && this.props.nextPage!==undefined) {
            if(event.deltaY > 0) {
                deltaDown += event.deltaY
                deltaDown = Math.abs(deltaDown)
                if(deltaDown / SCROLL_AMOUNT > 1) {
                    //Add some delay to prevent excessive scrolling
                    this.sleep(NEXTPAGE_DELAY).then(() => {
                        navigate(this.props.nextPage,  { replace: false })
                    })
                }
            }
        }      
    }

   
    sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds))
    }    

    render() {
      return (
        <>
        <Parallax pages={this.props.totalPages} ref="parallax" scrolling={false}>
            {this.props.children}
        </Parallax>
        <ArrowButton isShow={false} isOnSide={this.state.isMiddle} isSideway={this.state.isSideway} nextPageLabel={this.props.nextPageLabel} nextPage={this.props.nextPage}/>
        </>
       )
    }
  }